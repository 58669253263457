.rd-mailform {
	.has-error {
		.invalid-feedback {
			display: block;
		}
	}
	.snackbars {
		border: 1px solid $gray-200;
		background: $gray-200;
	    border-radius: 3px;
	    font-size: 14px;
	    font-weight: 500;
	    margin-top: 20px;
	    display: none;
	    &.active {
	    	display: block;
	    }
	    &.error {
    	    border: 1px solid lighten($danger, 5%);
		    background: lighten($danger, 2%);
		    color: $danger;
	    }
	    &.success {
    	    border: 1px solid lighten($success, 5%);
		    background: lighten($success, 2%);
		    color: $success;
	    }
		p {
			margin: 0;
			position: relative;
			display: flex;
			align-items: center;
			.s-icon {
				width: 40px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				font-size: 16px;
			}
		}
	}
}


.form-switch {
	.form-check-input {
		height: 1.5em;
	}
}