@each $color, $value in $social-colors {
  .text-#{$color} {
    color: $value !important;
  }
}


@each $color, $value in $social-colors {
  .bg-#{$color} {
    background: $value;
  }
}
