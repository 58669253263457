// Sm hover shadow
.shadow-sm-hover {
	&:hover {
		box-shadow: $box-shadow-sm-hover !important;	
	}
}

// hover shadow
.shadow-hover {
	&:hover {
		box-shadow: $box-shadow-hover !important;	
	}
}

// Lg shadow
.shadow-lg-hover {
	&:hover {
		box-shadow: $box-shadow-lg-hover !important;	
	}
}
