body,
html {
    width: 100%;
	height: 100%;
}

.wrapper {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    min-height: 100%;
    position: relative;
    > main {
	    flex: 1 0 auto;
    }
    > * {
    	flex: 0 0 auto;
    }
}